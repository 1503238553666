import React from 'react'
import { Layout } from '../components/common'
import styled from 'styled-components'
import { Container } from 'reactstrap'
import { FixedObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

interface PageNotFoundQuery {
  img: {
    fixed: FixedObject
    title: string
  }
}

const Page404: React.FC = () => {
  const data = useStaticQuery<PageNotFoundQuery>(graphql`
    query PageNotFound {
      img: contentfulAsset(id: { eq: "5572c955-4835-5a3c-8bad-658fabe43662" }) {
        fixed(quality: 100) {
          ...GatsbyContentfulFixed_withWebp
        }
        title
      }
    }
  `)

  return (
    <Layout seo={{ lang: 'en', title: 'Not found' }}>
      <StyledContainer>
        <Img alt={data.img.title} fixed={data.img.fixed} />
        <Number>404</Number>
        <ErrorMsg>Page Not Found</ErrorMsg>
      </StyledContainer>
    </Layout>
  )
}

export default Page404

const StyledContainer = styled(Container)`
  color: #555;
  text-align: center;
`

const Number = styled.h1`
  font-size: 10rem;
`

const ErrorMsg = styled.h1`
  margin-bottom: 2rem;
`
